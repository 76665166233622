import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {TooltipDirective} from '../../../oex-ui-kit/components/tooltip/tooltip.directive';
import {ActivatedRoute, ActivatedRouteSnapshot, ResolveFn, Router} from '@angular/router';
import {IPagingData} from '../../services/packageTypes';
import {IInquiry, IInquiryStatus} from '../../services/api.types';
import {APIService} from '../../services/api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MarkdownDirective} from '../../../oex-ui-kit/components/markdown/markdown.directive';
import {SearchComponent} from '../../../oex-ui-kit/components/search/search';
import {AuthService} from '../../services/authService';

export interface IInquiresModel {
  statuses: IInquiryStatus[],
  inquiries: IPagingData<IInquiry>,
  search: string,
  isPublisher: boolean
  sorting: string;
}

export const resolveInquiries: ResolveFn<IInquiresModel> = async (route: ActivatedRouteSnapshot) => {
  const isPublisher = !!route.data.publisher;
  const api = inject(APIService);
  const auth = inject(AuthService);
  const search = route.queryParams.search ?? '';
  const resp = await Promise.all([
    api.getInquiryStatuses(),
    api.getInquiries(auth.getAdministratedCompany()?.nws || '', search, isPublisher)
  ]);
  return {
    statuses: resp[0],
    inquiries: resp[1],
    isPublisher,
    search
  } as unknown as IInquiresModel;
}

@Component({
  selector: 'oex-hih-inquiries',
  standalone: true,
  templateUrl: './inquires.component.html',
  styleUrls: ['./../../controls/tile/status.scss', './inquires.component.scss'],
  imports: [
    TooltipDirective,
    MarkdownDirective,
    SearchComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InquiresComponent implements OnInit {
  data$ = this.route.data.pipe(takeUntilDestroyed());
  model!: IInquiresModel;
  statusMap: { [id: number]: IInquiryStatus } = {};

  constructor(private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private router: Router) {
  }

  ngOnInit() {
    this.data$.subscribe(d => {
      this.model = d.data;
      this.buildStatusMap();
      this.cdr.detectChanges();
    });
  }

  onSearchHandler(term: string) {
    void this.router.navigate([], {queryParams: {search: term}});
  }

  private buildStatusMap() {
    this.model?.statuses.forEach(st => {
      this.statusMap[st.id] = st;
    });
  }
}
