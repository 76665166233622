<!-- Title -->
<h3 class="ui-portal-title">
  {{model.isPublisher ? 'Health Innovation Hub Inquiries' : 'My Inquires'}}
</h3>

@if (model.isPublisher) {
  <ui-search [value]="model.search" [showButton]="false" [showIcon]="true" (onSearch)="onSearchHandler($event)"></ui-search>
}

<!-- List -->
@for (item of model.inquiries.items; track item.tid) {
  <div class="item">
    <h3>
      <!-- Product -->
      {{ item.productName }}

      <!-- Status -->
      @if (!model.isPublisher) {
        @if (statusMap[item.status ?? '']; as st) {
          <span class="mp-status" [style.color]="st.color" [style.background-color]="st.background">{{ st.name }}</span>
        }
      }
    </h3>

    @if (model.isPublisher) {
      <!-- From -->
      <p>
        From: {{ item.firstName + ' ' + item.lastName }}, {{ item.jobTitle }}, {{ item.companyName }}
        <img src="/assets/img/official-maintainer.svg" class="ico-official" ui-tooltip="Verified Buyer">
      </p>

      <!-- Industry -->
      <p>
        Industry: {{ item.industry }}
      </p>

      <!-- Country -->
      <p>
        Region: {{ item.country }}
      </p>

      <!-- Email -->
      <p>
        Email: <a [href]="'mailto:'+item.email" class="ui-link text">{{ item.email }}</a>
      </p>

      <!-- Phone -->
      @if (item.phoneNumber) {
        <p>
          Phone: <a [href]="'tel:'+item.phoneNumber" class="ui-link text">{{ item.phoneNumber }}</a>
        </p>
      }
    } @else {
      <!-- Company -->
      <p>
        Company: {{ item.toCompanyName }}
      </p>
    }

    <!-- Message -->
    <p [ui-markdown]="item.message" class="txt-message"></p>
  </div>
}
